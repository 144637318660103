<template>
  <a-modal
    class="category-edit-modal"
    title="Редактировать категорию"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="category">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Название">
              <a-input
                  ref="categoryName"
                  placeholder="Введите название"
                  v-model:value="category.name"
              />
            </a-form-item>
          </div>
          <div class="col-md-12">

            <a-form-item label="Переводы" v-if="category.locales.length">
              <div class="container pb-3" v-for="(model, key) in category.locales" :key="key">
                <div class="row">
                  <div class="col-md-8">
                    <div class="row pb-3">
                      <a-input placeholder="Введите название" v-model:value="model.name"></a-input>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <a-select class="pl-3" v-model:value="model.locale">
                        <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="row">
                      <a-button @click="removeLocale(key)" type="link" danger><MinusCircleOutlined /></a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-button
                @click="addLocale"
                shape="round"
                type="link"
                class="px-0 pt-0 pb-5"
            >
              <template #icon>
                <PlusCircleOutlined />
              </template>Добавить перевод</a-button>
          </div>
          <div class="col-md-12">
            <a-form-item label="Выберите филиал" v-if="stores.length">
              <div>
                <a-checkbox
                  v-model:checked="state.checkAll"
                  :indeterminate="state.indeterminate"
                  @change="onCheckAllChange"
                >
                  Выбрать все
                </a-checkbox>
              </div>
              <a-divider/>
              <a-checkbox-group v-model:value="state.checkedList" :options="stores">
                <template #label="{ value }">
                  <span style="color: red">{{ value }}</span>
                </template>
              </a-checkbox-group>
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, watch, toRefs, onMounted, nextTick} from 'vue';
import apiClient from "@/services/axios";
import Category from "@/models/Category";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons-vue";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  categoryId: {
    type: Number,
    default: 0,
  },
  modal: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  categoryName = ref("categoryName"),
  initialCategory = new Category({}),
  category = reactive(initialCategory);

const stores = ref([]);
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: [],
});

toRefs(state);

const
  addLocale = () => {
    category.locales.push({
      id: null,
      name: null,
      locale: props.locales.find(() => true).short,
    })
  },
  removeLocale = (key) => {
    category.locales.splice(key, 1)
  },
  resetForm = () => {
    Object.assign(category, new Category({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(category);

    return apiClient.post(`/menu/category-update?category=${props.categoryId}`, {
      stores: state.checkedList,
      ...model,
    }).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  },

  onCheckAllChange = (e) => {
    Object.assign(state, {
      checkedList: e.target.checked ? stores.value.map(key => key.value) : [],
      indeterminate: false,
    });

  },
  getCategory = () => {
    apiClient.get(`/menu/category-view?uuid=${props.categoryId}`).then(response => {
      if (response.data) {
        stores.value = response.data.data.stores;
        modalVisible.value = true;
        let model = new Category(response.data.data)
        Object.assign(category, model);
        state.checkedList = response.data.data['selected_stores'];
        nextTick(() => categoryName.value?.focus());
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false;
    })
  }


watch(
  () => state.checkedList,
  val => {
    state.indeterminate = !!val.length && val.length < stores.value.length;
    state.checkAll = val.length === stores.value.length;
  },
);

watch(
  () => props.modal,
  async val => {
    if (val) {
      await getCategory();
    }
  },
);

</script>

<style scoped>
@import "./categories.css";
</style>
